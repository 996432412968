$( document ).ready(function() {
    if(loggedIn){
        getNavSummary();   
    }

    // if (window.location.hash) {
    //     $('html, body').animate({
    //         scrollTop: $(window.location.hash).offset().top - 38
    //     }, 1000);
    // }
    $('form#login-form').submit(function () {
        $(this).find('button[type=submit]').attr('disabled', 'disabled');
        $(this).find('button[type=submit]').text('logging in');
    });
    $("#login-div-close-div").on("click", function (event) {
        event.preventDefault();
        $(".login-div").slideUp('slow');
    });
    $("#navbar-login-btn").on("click", function (event) {
        event.preventDefault();
        $(".login-div").slideDown('slow');
    });
});

